
import './App.css';

import React from 'react';

import { Button, Grid } from '@material-ui/core';
import MyApp, { MyAppInfo } from './MyApp';



function App() {

  var apps : MyAppInfo[] = [{
    url : "https://play.google.com/store/apps/details?id=com.peachstudio.balancemaster.play" ,
    title: "Balance Master HD 2" ,
    image : "/bm/ic_launcher2-web.png",
    subtitle : "Free 2D Physics Game, balance blocks",
    introduction: "A physics balance game, for every puzzle, your GOAL is place all shapes without dropping them out of screen. let's see who will get the Perfect Balance!",
  },
  {
    url : "https://play.google.com/store/apps/details?id=com.shark.bubble.breaker" ,
    title: "Classic Bubble Breaker" ,
    image : "/cbb/ic_launcher2-web.png",
    subtitle : "the most classic version of bubble breaker",
    introduction: "Classic puzzle game★, tap connected bubbles with same color to score. Try to score as higher As you can! Compete with others by the online score ranking system.",
  },
  {
    url : "https://play.google.com/store/apps/details?id=com.peachstudio.bubble.ocean" ,
    title: "Ocean Bubble" ,
    image : "/ob/ic_launcher2-web.png",
    subtitle : "Bubble Shooting game, multiple game modes!",
    introduction: `Solo Arcade consisting of 800+ Levels! Solo Survival mode, Free Level Online Store and Powerful Level Editor
     Battles over WiFi or Bluetooth, allows two players play game on one device and Offers VS against AI’s of various Levels.`,
  },
  {
    url : "https://play.google.com/store/apps/details?id=com.peachstudio.rocketdriver" ,
    title: "Rocket Driver" ,
    image : "/rd/ic_launcher2-web.png",
    subtitle : "Tired of driving cars, why not try something new?",
    introduction: `Features:
    Easy Control, use gravity sensor to turn around.
    3 types of rocket for your choice, different look, different feel.
    5 attributes of rocket are open for upgrade.
    20 different levels.
    Endless mode is already available!",`
  },
]
  

  return (
    <div className="App">
      <img src="/studio_logo.png"></img>

      <Grid container justifyContent="center" spacing={2}>

       {apps.map((value) => (
        <Grid key={1} item>
            <MyApp appInfo={value}></MyApp>
        </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default App;
