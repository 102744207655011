
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Button, Grid } from '@material-ui/core';
import SharePanel from './SharePanel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 345,
        },
        media: {
            // height: 0,
            // paddingTop: '56.25%', // 16:9

            height: 350,    
            // marginLeft: '33%'
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
    }),
);



export type MyAppInfo = {
    url: string,
    title: string,
    image: string,
    subtitle: string,
    introduction: string
  }

  export type MyAppProps = {
      appInfo: MyAppInfo
  }

function MyApp(props : MyAppProps) {
    const classes = useStyles();
    const appInfo = props.appInfo;

    const [sharing, setSharing] = useState<Boolean>(false);


    return (

        <Card className={classes.root}>
            
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        {appInfo.title.charAt(0)}
                    </Avatar>
                }
                action={
                    <IconButton aria-label="share" onClick={() => {
                        setSharing(!sharing);
                    }}>
                        <ShareIcon />
                    </IconButton>
                }
                title={appInfo.title} 
                subheader={appInfo.subtitle}
            />
            { sharing && 
                (
                    <SharePanel url={appInfo.url} title={appInfo.title}  image={appInfo.image} ></SharePanel>
                )
            }
            <CardMedia
                className={classes.media}
                image={appInfo.image} 
                title={appInfo.title} 

            />
            
            <CardActions >
                <Button onClick={() => {
                    window.open(appInfo.url, "_blank")
                }}>
                    <img src={"./get_it_on_google_play.png"} />
                </Button>


            </CardActions>

            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                   {appInfo.introduction}
                </Typography>
            </CardContent>

        </Card>

    );
}

export default MyApp